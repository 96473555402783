<template>
<div id="login">
  <div class="login_list">
    <div class="login_mu">
      <div class="login_list-l">
        <img src="../../assets/images/login-bgl1.png" alt="">
      </div>
      <div class="login_center">
        <div class="login-by">
          <h3>Hello,欢迎登录</h3>
          <p>WELCOME TO THE SYSTEM</p>
                  <el-form :model="form" status-icon :rules="rules" ref="ruleForm"
                           class="login-form">
<!--                    <div class="user_title">账号</div>-->
                    <el-form-item prop="username"  class="form-item margin-item">
                      <el-input v-model="form.username" autocomplete="off"
                                placeholder="请输入账号"></el-input>
                    </el-form-item>
<!--                    <div class="user_title">密码</div>-->
                    <el-form-item prop="password"
                                  class="form-item"
                                  >
                      <el-input autocomplete="off"
                                type="password" v-model="form.password"
                                placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item class="to-lead-item">
                      <el-checkbox v-model="checked">记住密码</el-checkbox>
                    </el-form-item>
                    <el-form-item>
                      <el-button style="font-size: 24px;" type="primary" class="login-btn" @click="submit('form')">登录
                      </el-button>
                    </el-form-item>
                  </el-form>
        </div>


      </div>
    </div>
  </div>
</div>
</template>

<script>
import api from '@/service/api/api'
export default {
  name: "login",
  data() {
    return {
      form: {
        // 18380132751
        // 132751
        username: '',
        password: '',
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ]
      },
      checked:true
    }
  },
  methods: {
    submit() {
      let _this = this
      this.$refs.ruleForm.validate((e) => {
        // console.log(e)
        if (e) { // 符合规范请求登录
          _this.login();
        } else { // 不符合规范提示用户
          return false
        }


      })
    },
    login() {
      let username = this.form.username
      let password = this.form.password
      // 储存账号密码
      localStorage.setItem('username',username)
      localStorage.setItem('password',password)
      // this.$router.push('/index')
      let aid = this.$route.query.aid?this.$route.query.aid:1
      let params = {
        username:this.form.username,
        password:this.form.password,
      }

      console.log(params)
      // 储存aid
      localStorage.setItem('acctid',aid)
      api.login(params).then(res => {
        console.log(res)
        if (res.errcode==0) {
          let username = this.form.username
          let password = this.form.password
          // 储存账号密码
          localStorage.setItem('username',username)
          localStorage.setItem('password',password)
          // 存储用户信息
             let headimg=res.data.headimg
             let logo=res.data.logo
             let usernames=res.data.username
             let casenumber = res.data.casenumber
          localStorage.setItem('headimg',headimg)
          localStorage.setItem('logo',logo)
          localStorage.setItem('usernames',usernames)
          localStorage.setItem('casenumber',casenumber)
          this.$router.push('/index')
        } else {
          this.$message({
            message: '账号或密码错误',
            center: true,
            type: 'error'
          });
        }
      }).catch(err => {
        console.log(err)
        this.$message({
          message: err,
          center: true,
          type: 'error'
        });
      })
    }
  },
  created() {
    // console.log(this.$route.query)
    let that = this;
    // console.log(that.$route.path)
    document.onkeydown = function (e) {
      e = window.event || e;
      if (that.$route.path == '/' && (e.code == 'Enter' || e.code == 'enter')) {//验证在登录界面和按得键是回车键enter
        that.submit();//登录函数
      }
    }
  }
}
</script>

<style scoped>
#login {
  width: 100%;
  height: 100%;
  /*min-height: 1900px;*/
  background-color: #141621;
}
.login_list {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-position: center;
  display: flex;
}
.login_mu {
  width: 65%;
  height: 60%;
  background-color: #FFFFFF;
  border-radius: 10px;
  position: fixed;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  /*margin: 100px auto;*/
  display: flex;
  justify-content: space-between;
}
.login_list-l {
  width: 1087px;
}
.login_list-l>img {
  width: 100%;
  height: 100%;
}
.login_center {
  width: 100%;
  position: relative;
  /*background: pink;*/
  border-radius: 12px;
}
.login-by {
  width: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /*background-color: #999999;*/
}
.login-by>h3{
  font-size: 44px;
  font-weight: 400;
  color: #3CADFD;
  text-align: center;
  background: linear-gradient(0deg, #2F81ED 0%, #2EE9D8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 3% 0 10px 0;
}
.login-by p {
  height: 17px;
  text-align: center;
  font-size: 21px;
  font-weight: 400;
  color: #B4B4B4;
  line-height: 24px;
}
.login-form {
  margin: 10px auto;
  width: 90%;
  /*height: 285px;*/
}

el-form-item {
  margin-bottom: 10px;
  background-color: #F6F7FB;
  height: 50px;line-height: 50px;
  border-radius: 32px;
}
.margin-item {
  margin: 50px 0 30px 0;
}
.login-btn {
  width: 100%;
  color: #FFFFFF;
  margin-top: 10px;
  background: linear-gradient(0deg, #2F81ED, #2EE9D8);
  box-shadow: 2px 8px 9px 0px rgba(138, 138, 138, 0.12);
  cursor: pointer;
  border-radius: 32px!important;
}

</style>